function checkWindowSize() {
  var nav = document.querySelector('.nav');
  if (window.innerWidth >= 1024) {
    nav.classList.remove('collapsible');
  } else {
    nav.classList.add('collapsible');
  }
}

// Event Listener für Änderungen der Fenstergröße
window.addEventListener('resize', checkWindowSize);

// Aufruf bei initialer Ladung
checkWindowSize();

const collapsibles = document.querySelectorAll(".collapsible");
collapsibles.forEach((item) =>
  item.addEventListener("click", function () {
    console.log(collapsibles)
    this.classList.toggle("collapsible--expanded");
  })
);